import React from 'react';
import styled from 'styled-components';

interface ICenterTextProps {
  text?: string | null;
}

const CenterText: React.FC<ICenterTextProps> = ({ text }) => (
  <Container>
    <h2>{text}</h2>
  </Container>
);

const Container = styled.div`
  padding: 40px 0;
`;

export default CenterText;
